import React from "react";
import Layout from "../../../Layout";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../page-style.scss";

const data = (function () {
  let imgArray = [];
  for (let i = 1; i <= 2; i++) {
    let imgUrl = `https://chinmayavvdelhi.ac.in/assets/images/calendar/19-feb-2024-1/pic${i}.jpeg`;
    imgArray.push(imgUrl);
  }

  return imgArray;
})();

export default function InteractiveSessionbySwamiSwaroopananda2024() {
  const settings = {
    infinite: true,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  function ImageSlider() {
    return (
      <div className="calendar-page__slider">
        <Slider {...settings}>
          {data.map((item, i) => (
            <div className="calendar-page__images__slide">
              <div className="calendar-page__images__card">
                <img alt="" src={item}></img>
              </div>
              <br />
              <center>
                <h3>{item.titleSubHead}</h3>
              </center>
            </div>
          ))}
        </Slider>
      </div>
    );
  }

  return (
    <Layout>
      {/* <SEO
        title="School Gallery | Chinmaya Vidyalaya Vasant Vihar"
        description="Explore photos and videos of the campus and student activities  at the Vidyalaya for a holistic view of student life."
      /> */}

      <div className="calendar-page">
        <div className="goback">
          <a href="/school-events/">
            <i className="fa-solid fa-arrow-left"></i>Go Back
          </a>
        </div>
        <h1 className="heading">
          Interactive Session By Swami Swaroopananda Ji
        </h1>

        <p>
          Chinmaya Vidyalaya recently played host to Swami Swaroopananda Ji for
          an interactive session on ‘Unlocking Gen Z - Cracking the Codes’.
          <br />
          <br />
          The session was attended by the teachers of the Vidyalaya and was
          moderated by Shri Vinay Bharadwaj.
          <br />
          <br />
          Swami Ji shared his profound insights on understanding and connecting
          with the younger generation. The current generation is unique in terms
          of exposure to technology and information.
          <br />
          <br />
          He guided the teachers on ways to bridge the generational gap and form
          a meaningful connection with the students. He underlined the pivotal
          role teachers play in mentoring learners and helping them discover
          themselves and achieve their highest potential.
          <br />
          <br />
          Swami Ji answered all the questions patiently and encouraged the
          educators to support the students in pursuing their passion and being
          their authentic selves.
          <br />
          <br />
          The session was very well-received, and all the participants felt
          empowered and equipped to forge the next generation of leaders.
        </p>
        <section className="calendar-page__videosection">
          <ImageSlider />
        </section>
      </div>
    </Layout>
  );
}
